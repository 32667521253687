<template>
  <div class="locale">
    <h2 class="locale__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900">
      {{ $t('account.locale.title') }}
    </h2>

    <form
      class="locale__form tw-w-full"
      :disabled="$wait.is('updating locale')"
      @submit.prevent="submitted"
    >
      <p
        class="locale__form__locale-explanation tw-italic tw-leading-tight"
        data-test="locale-explanation"
      >
        {{ $t('account.locale.paragraphs.select_locale') }}
      </p>

      <ValidationObserver
        ref="observer"
      >
        <ValidationProvider
          ref="locale-provider"
          :name="$t('account.locale.fields.locale')"
          rules="required"
          slim
        >
          <template slot-scope="{ invalid, errors }">
            <b-form-radio-group
              v-model="formData.locale"
              :disabled="$wait.is('updating locale')"
              data-test="locales"
              name="locales"
              stacked
              class="focus:tw-outline-none"
            >
              <b-form-radio
                v-for="locale in locales"
                :key="locale.value"
                :value="locale.value"
                class="locale-radio"
              >
                <div class="tw-flex tw-items-center locale-radio__content tw-relative">
                  <ui-flag
                    :country="locale.country"
                    inline
                  />
                  <span
                    class="locale-radio__content__name"
                    v-text="locale.name"
                  />
                </div>
              </b-form-radio>
            </b-form-radio-group>

            <div
              v-if="invalid"
              class="tw-text-sm tw-mt-2 tw-text-red-500 locale__form__field-error"
            >
              {{ errors[0] }}
            </div>
          </template>
        </ValidationProvider>

        <div class="locale__form__buttons tw-flex tw-justify-between tw-mt-10">
          <ui-button
            :disabled="!isDirty || $wait.is('updating locale')"
            variant="link"
            type="reset"
            data-test="cancel-button"
            @click="reset"
          >
            {{ $t('account.buttons.cancel') }}
          </ui-button>
          <ui-button
            :loading="$wait.is('updating locale')"
            :disabled="$wait.is('updating locale')"
            class="locale__form__buttons__save tw-mr-2"
            variant="primary"
            type="submit"
            data-test="save-button"
          >
            {{ $t('account.buttons.save') }}
          </ui-button>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import store from '@/store'
  import { Me } from '@/resources'
  import handlePropertyPathViolations from '@/resources/handlers/violations'

  import { supportedLocales } from '@/locales/constants'
  import { loadLanguageAsync } from '@/locales'
  import Storage from '@/services/Storage'

  /**
   * @module view - locale
   */
  export default {
    name: 'Locale',
    metaInfo () {
      return {
        title: this.$t('account.locale.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        formData: {
          locale: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      /**
       * Returns true if one of the fields is dirty
       * @function isDirty
       * @returns {boolean}
       */
      isDirty () {
        const { locale } = this.getUserInfos

        return this.formData.locale &&
          locale.slice(0, 2) !== this.formData.locale
      },
      /**
       * Returns a mapped list of locales, with the associated country flag.
       * @function locales
       * @returns {array}
       */
      locales () {
        return supportedLocales
          .map(({ name, locale }) => ({
            value: locale,
            name,
            country: locale === 'en' ? 'gb' : locale
          }))
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      ...mapActions('auth', [
        'setUserInfos'
      ]),
      submitted () {
        if (this.$wait.is('updating locale')) {
          return
        }

        const { locale } = this.formData
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) {
              return
            }

            this.$wait.start('updating locale')
            Me.locale({}, {
              locale
            })
              .then(async () => {
                /**
                 * Change the app locale
                 */
                await loadLanguageAsync(locale)
                Storage && Storage.setItem('userLocale', locale)

                this.setUserInfos({
                  ...this.getUserInfos,
                  locale
                })

                showToaster(
                  this,
                  this.$t('account.locale.paragraphs.locale_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(this, data.error.violations)
                } else {
                  showToaster(
                    this,
                    data.error.title, {
                      type: 'error',
                      position: 'bottom-right'
                    }
                  )
                }
              })
              .finally(() => this.$wait.end('updating locale'))
          })
      },
      reset () {
        const locale = this.getUserInfos.locale.slice(0, 2)
        const mappedLocales = this.locales.map(v => v.value)

        if (mappedLocales.includes(locale)) {
          this.formData = {
            locale
          }
          this.$refs.observer.reset()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .locale {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }

    &__form {
      &__field-error {
        border-top: 1px solid $danger;
      }
    }

    &-radio {
      &__content {
        top: -2px;

        &__name {
          vertical-align: middle;
        }
      }
    }
  }

</style>
